import axios from "axios";
import router from "@/router";

const service = axios.create({
    baseURL: "/api/",
    json: true
});

service.interceptors.response.use(
    (response) => response,
    (error) => {
        const response = error.response;
        if (response) {
            var skippedPaths = [
                "/process-logs",
                "/status-process-parts",
                "/status-requests"
            ];
            if (response.status === 401) {
                router.push("/login?redirecturl=" + router.currentRoute.path);
            } else if (
                response.status === 403 ||
                (response.status === 500 &&
                    !skippedPaths.includes(response.config.url))
            ) {
                router.push("/error/" + response.status);
            } else if (response.status === 409){
                error = response.data;
            }
        }
        return Promise.reject(error);
    }
);

export default service;
