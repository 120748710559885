import service from "./api.js";

export function fetch() {
    return service({
        url: "/model-score",
        method: "get"
    });
}

export function saveOrUpdate(data) {
    return service({
        url: "/model-score",
        method: "post",
        data
    });
}