<template src="./Campaigns.html"></template>

<script>
import { mapGetters, mapActions } from "vuex";
import DataGridHeader from "@/components/DataGridHeader";
import GenericDataGrid from "@/components/GenericDataGrid";
import PageLeavePopup from "@/components/PageLeavePopup";
import UnsavedChangesPopup from "@/components/UnsavedChangesPopup";
import EmailRecipientsPopup from "@/components/EmailRecipientsPopup";
import CustomStore from "devextreme/data/custom_store";
import { store } from "@/store/index";
import notify from "devextreme/ui/notify";
import unsyncChangesTables from "@/constants/unsyncChangesTables";
import { generateParams } from "@/utils/params";
import { DxSelectBox } from "devextreme-vue/select-box";
import { LpsExceptionStepLookup } from "@/constants/lpsExceptionStepLookup";

const customStore = new CustomStore({
    key: "taLpsCampaignId",
    async load(loadOptions) {
        await store.dispatch(
            "campaigns/fetchCampaigns",
            generateParams(loadOptions)
        );
        await store.dispatch("campaigns/fetchUnprocessedCampaignsCount");

        return store.getters["campaigns/getCampaigns"];
    },
    totalCount() {
        return store.getters["campaigns/getTotalCount"];
    },
    insert() {},
    update() {}
});

export default {
    components: {
        DataGridHeader,
        GenericDataGrid,
        PageLeavePopup,
        UnsavedChangesPopup,
        EmailRecipientsPopup,
        DxSelectBox
    },
    name: "Campaigns",
    data() {
        return {
            to: null,
            showDialog: false,
            showUnsavedChangesDialog: false,
            showRecipientsPopup: false,
            isProcessable: false,
            dataSource: customStore,
            defaultValues: {},
            gridColumns: [
                {
                    title: "Kampagne ID",
                    dataField: "campaignNbr",
                    blockSorting: true,
                    blockEditing: true,
                    width: 100,
                    filterOperations: ["startswith"]
                },
                {
                    title: "Kampagne",
                    dataField: "campaignName",
                    blockFiltering: false,
                    blockSorting: true,
                    filterOperations: ["contains"]
                },
                {
                    title: "Scoring Flag",
                    dataField: "adobeSortingFg",
                    blockFiltering: true,
                    blockSorting: true,
                    editorOptions: { readOnly: true },
                    width: 140,
                    lookup: {
                        dataSource: [
                            { id: false, name: "Keine Ausnahme" },
                            { id: true, name: "Score-Ausnahme" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                },
                {
                    title: "Importdatum",
                    dataField: "firstDlTs",
                    dataType: "datetime",
                    blockFiltering: true,
                    blockSorting: true,
                    format: "dd.MM.yyyy HH:mm",
                    width: 150,
                    editorOptions: {
                        readOnly: true,
                        displayFormat: "dd.MM.yyyy HH:mm",
                        type: "datetime"
                    }
                },
                {
                    title: "NSP-Prüfung",
                    dataField: "checkedInTdTs",
                    dataType: "datetime",
                    blockFiltering: true,
                    format: "dd.MM.yyyy HH:mm",
                    width: 150,
                    editorOptions: {
                        readOnly: true,
                        displayFormat: "dd.MM.yyyy HH:mm",
                        type: "datetime"
                    }
                },
                {
                    title: "Meldedatum",
                    dataField: "sentToBmwTs",
                    dataType: "datetime",
                    blockFiltering: true,
                    blockSorting: true,
                    width: 115,
                    format: "dd.MM.yyyy",
                    editorOptions: {
                        displayFormat: "dd.MM.yyyy",
                        type: "date",
                        placeholder: "Nicht gemeldet"
                    }
                },
                {
                    title: "Freigabedatum",
                    dataField: "approvedByBmwTs",
                    required: true,
                    dataType: "datetime",
                    blockFiltering: true,
                    blockSorting: true,
                    width: 115,
                    format: "dd.MM.yyyy",
                    editorOptions: {
                        displayFormat: "dd.MM.yyyy",
                        type: "date",
                        placeholder: "Nicht freigegeben"
                    }
                },
                {
                    title: "LPS-Ausnahme",
                    dataField: "lpsExceptionFg",
                    blockFiltering: true,
                    blockSorting: true,
                    width: 145,
                    lookup: {
                        dataSource: [
                            { id: false, name: "Normal" },
                            { id: true, name: "LPS-Ausnahme" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                },
                {
                    title: "Abgestufte Sonderbehandlung",
                    dataField: "lpsExceptionStep",
                    blockFiltering: true,
                    blockSorting: true,
                    width: 220,
                    lookup: {
                        dataSource: LpsExceptionStepLookup,
                        valueExpr: "id",
                        displayExpr: "name"
                    },
                },
                {
                    title: "Status",
                    dataField: "lpsStatus",
                    blockSorting: true,
                    width: 130,
                    lookup: {
                        dataSource: [
                            { id: "unknown", name: "Offen" },
                            { id: "blocked", name: "Blockiert" },
                            { id: "forwarding", name: "Freigegeben" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                },
                {
                    title: "Letzter Bearbeiter",
                    dataField: "campaignUser",
                    blockFiltering: true,
                    blockSorting: true,
                    editorOptions: { readOnly: true }
                },
                {
                    title: "Letzte Änderung",
                    dataField: "campaignTs",
                    dataType: "datetime",
                    blockFiltering: true,
                    blockSorting: true,
                    format: "dd.MM.yyyy HH:mm",
                    width: 150,
                    editorOptions: {
                        readOnly: true,
                        displayFormat: "dd.MM.yyyy HH:mm",
                        type: "datetime"
                    }
                }
            ],
            defaultColumns: [
                "Kampagne",
                "Kampagne ID",
                "Scoring Flag",
                "TEMPORARY_DL",
                "TEMPORARY_CHECKED_IN_DL",
                "Meldedatum",
                "Freigabedatum",
                "LPS-Ausnahme",
                "Abgestufte Sonderbehandlung",
                "Status"
            ]
        };
    },
    computed: {
        ...mapGetters("campaigns", ["getCampaigns"]),
        ...mapGetters("unsyncChanges", ["hasUnsyncChanges"]),
        formStructure() {
            return [
                {
                    title: "",
                    items: [
                        {
                            label: "Kampagne",
                            name: "campaignName",
                            span: this.isProcessable ? 11 : 8,
                            editorOptions: {
                                readOnly: true
                            }
                        },
                        {
                            label: "Importdatum",
                            name: "firstDlTs",
                            span: 3
                        },
                        {
                            label: "NSP-Prüfung",
                            name: "checkedInTdTs",
                            span: 3
                        },
                        {
                            label: "Meldedatum",
                            name: "sentToBmwTs",
                            span: 3
                        },
                        {
                            label: "Freigabedatum",
                            name: "approvedByBmwTs",
                            span: 3,
                            hide: this.isProcessable
                        },
                        {
                            label: "Kampagne ID",
                            name: "campaignNbr",
                            span: 2
                        },
                        {
                            label: "Preferred Medium",
                            name: "prefMedium",
                            span: this.isProcessable ? 7 : 4,
                            editorOptions: {
                                readOnly: true
                            }
                        },
                        {
                            label: "Scoring Flag",
                            name: "adobeSortingFg",
                            span: 2,
                            hideLabel: true,
                            editorType: "dxSwitch",
                            editorOptions: {
                                switchedOffText: "Keine Ausnahme",
                                switchedOnText: "Score-Ausnahme",
                                width: "100",
                                elementAttr: { class: "read-only-switcher" },
                                hint: "Scoring Flag aus der Kampagnen-Anlage"
                            }
                        },
                        {
                            label: "LPS-Ausnahme",
                            name: "lpsExceptionFg",
                            span: 2,
                            hideLabel: true,
                            editorType: "dxSwitch",
                            editorOptions: {
                                switchedOffText: "Normal",
                                switchedOnText: "LPS-Ausnahme",
                                width: "100",
                                hint: "Behandlung als LPS-Ausnahme oder im normalen Prozess"
                            }
                        },
                        {
                            label: "Abgestufte Sonderbehandlung",
                            name: "lpsExceptionStep",
                            span: 4,
                            hideLabel: true,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                items: LpsExceptionStepLookup,
                                displayExpr: "name",
                                valueExpr: "id",
                                hint: "Deaktivierung definierter Regeln inkl. Scoring. EWE-Prüfung bleibt aktiviert."
                            }
                        },
                        {
                            label: "Kommentar",
                            name: "releaseComment",
                            span: this.isProcessable ? 3 : 4
                        },
                        {
                            label: "Status",
                            name: "lpsStatus",
                            span: 2,
                            hide: this.isProcessable
                        },
                        {
                            label: "Mehrfach WL",
                            name: "multipleForwarding",
                            span: 2,
                            hideLabel: true,
                            editorType: "dxSwitch",
                            editorOptions: {
                                switchedOffText: "Einfache WL",
                                switchedOnText: "Mehrfache WL",
                                width: "100"
                            }
                        },
                        {
                            label: "Relevanz Händlergrenze",
                            name: "includeForUpperForwardingLimit",
                            span: 2,
                            hideLabel: true,
                            editorType: "dxSwitch",
                            editorOptions: {
                                switchedOffText: "Ohne Grenze",
                                switchedOnText: "WL/Tag achten",
                                width: "100",
                                hint: "Die Beachtung der Händlergrenze kann deaktiviert werden"
                            }
                        }
                    ]
                }
            ];
        },
        unsyncChangesCampaigns() {
            return unsyncChangesTables.CAMPAIGNS;
        },
        hasUnsyncChangesCampaigns() {
            return this.hasUnsyncChanges(this.unsyncChangesCampaigns);
        }
    },
    mounted() {
        this.fetchCampaigns({ skip: 0, take: 20 }).then(() => {
            if (this.getCampaigns.length > 0) {
                const keys = Object.keys(this.getCampaigns[0]);
                keys.forEach((key) => {
                    if (!this.gridColumns.find((x) => x.dataField === key)) {
                        this.gridColumns.push({
                            title: key,
                            dataField: key,
                            hideInColumnChooser: true
                        });
                    }
                });
            }
        });
        this.fetchEmailRecipients();
    },
    beforeRouteLeave(to, _, next) {
        if (to.path == "/login") {
            next();
        } else if (this.hasUnsyncChangesCampaigns) {
            this.to = to;
            this.showDialog = true;
        } else {
            next();
        }
    },
    methods: {
        ...mapActions("campaigns", [
            "fetchCampaigns",
            "updateCampaignStatus",
            "updateCampaign",
            "syncCampaigns",
            "fetchEmailRecipients",
        ]),
        updateProcessiable(record) {
            if (record.lpsStatus == "unknown") {
                this.isProcessable = true;
            } else {
                this.isProcessable = false;
            }
        },
        async updateStatus(campaign, status) {
            await this.updateCampaignStatus({
                model: campaign,
                status
            });
            this.$refs["data-grid-component"].gridReload();
        },
        async update(model) {
            await this.updateCampaign(model);
            this.$refs["data-grid-component"].gridReload();
        },
        onSync(payload) {
            if (payload.hasEditData) {
                this.showUnsavedChangesDialog = true;
            } else {
                this.synchronize(payload.updateUnsyncChanges);
            }
        },
        synchronize(updateUnsyncChanges) {
            this.syncCampaigns()
                .then(() => {
                    updateUnsyncChanges();
                    notify(
                        {
                            message: "Alle Änderungen synchronisiert",
                            position: {
                                my: "center top",
                                at: "center top"
                            }
                        },
                        "success",
                        3000
                    );
                })
                .catch((error) =>
                    notify(
                        {
                            message: error,
                            position: {
                                my: "center top",
                                at: "center top"
                            }
                        },
                        "error",
                        3000
                    )
                )
                .finally(() => {
                    this.$refs["data-grid-component"].gridReload();
                });
        }
    }
};
</script>

<style src="./Campaigns.css"></style>
