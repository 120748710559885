import { fetch, saveOrUpdate } from "@/utils/api/modelScores.js";
import notify from "devextreme/ui/notify";

const state = {
    modelScores: []
};

const getters = {
    getModelScores: (state) => {
        return state.modelScores;
    }
};

const mutations = {
    SET_MODEL_SCORES: (state, data) => {
        state.modelScores = data;
    }
};

const actions = {
    fetchModelScores({ commit }) {        
        return new Promise((resolve, reject) => {
            fetch()
                .then((response) => {                    
                    commit("SET_MODEL_SCORES", response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    },
    saveOrUpdateModelScore(_, data) {
        return new Promise((resolve, reject) => {
            saveOrUpdate(data)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    notify(
                        {
                            message: error,
                            position: {
                                my: "center top",
                                at: "center top"
                            }
                        },
                        "error",
                        5000
                    )
                    reject(error);
                });
        });
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};