const ALLOWED = "ALLOWED";
const USER_MANAGE = "BMW.LeadPreSelect.UserManage";
const UPDATE_SCORES = "BMW.LeadPreSelect.UpdateScores";
const CONTROL_AND_CAMPAIGNS_MANAGE = "BMW.LeadPreSelect.ControlAndCampaignsManage";

export default {
    ALLOWED: ALLOWED,
    USER_MANAGE: USER_MANAGE,
    UPDATE_SCORES: UPDATE_SCORES,
    CONTROL_AND_CAMPAIGNS_MANAGE: CONTROL_AND_CAMPAIGNS_MANAGE
}