import Vue from "vue";
import Vuex from "vuex";
import workData from "./modules/workData";
import statusNumbers from "./modules/statusNumbers";
import dealers from "./modules/dealers";
import campaigns from "./modules/campaigns";
import vehicles from "./modules/vehicles";
import scoreSteps from "./modules/scoreSteps";
import rules from "./modules/rules";
import blacklistDialingCodes from "./modules/blacklistDialingCodes";
import blacklistEmails from "./modules/blacklistEmails";
import blacklistNames from "./modules/blacklistNames";
import preferredDealers from "./modules/preferredDealers";
import roles from "./modules/roles";
import users from "./modules/users";
import account from "./modules/account";
import unsyncChanges from "./modules/unsyncChanges";
import statusRequests from "./modules/statusRequests";
import processLogs from "./modules/processLogs";
import statusProcessParts from "./modules/statusProcessParts";
import modelScores from "./modules/modelScores";

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        workData,
        statusNumbers,
        dealers,
        campaigns,
        vehicles,
        scoreSteps,
        rules,
        blacklistDialingCodes,
        blacklistEmails,
        blacklistNames,
        preferredDealers,
        roles,
        users,
        account,
        unsyncChanges,
        statusRequests,
        processLogs,
        statusProcessParts,
        modelScores
    }
});
