import { store } from "@/store";
import AuthConstants from "@/constants/authConstants"

function hasClaim(claim, claimValue) {
    const session = store.getters["account/getSession"];
    if (session?.user?.claims) {
        return session.user.claims[claim]?.find(cv => cv === claimValue) !== undefined;
    }

    return false;
}

function canManageUsers() {
    return hasClaim(AuthConstants.USER_MANAGE, "*");
}

function canUpdateScores() {
    return hasClaim(AuthConstants.UPDATE_SCORES, AuthConstants.ALLOWED);
}

function canManageControlAndCampaigns() {
    return hasClaim(AuthConstants.CONTROL_AND_CAMPAIGNS_MANAGE, AuthConstants.ALLOWED);
}

export default {
    canManageUsers,
    canUpdateScores,
    canManageControlAndCampaigns,
    hasClaim
}