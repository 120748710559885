<template src="./ModelScores.html"></template>

<script>
import { mapGetters, mapActions } from "vuex";
import DataGridHeader from "@/components/DataGridHeader";
import GenericDataGrid from "@/components/GenericDataGrid";
import CustomStore from "devextreme/data/custom_store";
import { store } from "@/store/index";

const customStore = new CustomStore({
    key: "modelScoreId",
    loadMode: "raw",
    async load() {
        await store.dispatch("scoreSteps/fetchScoreSteps");
        await store.dispatch("modelScores/fetchModelScores");
        return store.getters["modelScores/getModelScores"];
    },
    insert() { },
    update() { }
});
export default {
    components: {
        DataGridHeader,
        GenericDataGrid
    },
    name: "ModelScores",
    data() {
        return {
            dataSource: customStore,
            gridColumns: [
            {
                    title: "ID",
                    dataField: "modelScoreId",
                    width: 60
                },
                {
                    title: "Marke",
                    dataField: "brand",
                    width: 90,
                    required: true,
                    lookup: {
                        dataSource: [
                            { id: "BMW", name: "BMW" },
                            { id: "MINI", name: "MINI" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                },
                {
                    title: "Modell kurz",
                    dataField: "modelShort",
                    width: 120,
                    required: true
                },
                {
                    title: "Score-Stufe",
                    dataField: "modelStep",
                    width: 120,
                    required: true
                },
                {
                    title: "Score-Grenze",
                    dataField: "modelScoreValue",
                    width: 120,
                    required: true                    
                },
                {
                    title: "Score Aktiv",
                    dataField: "modelScoreActive",
                    width: 120,
                    lookup: {
                        dataSource: [
                            { id: true, name: "Aktiv" },
                            { id: false, name: "Inaktiv" }
                        ],
                        valueExpr: "id",
                        displayExpr: "name"
                    }
                },
                {
                    title: "Kommentar",
                    dataField: "comment",
                    required: false
                },
                {
                    title: "Letzter Bearbeiter",
                    dataField: "modelScoreUser",
                    width: 150,
                },                
                {
                    title: "Letzte Änderung",
                    dataField: "modelScoreTimestamp",
                    dataType: "datetime",
                    format: "dd.MM.yyyy HH:mm",
                    blockEditing: true,
                    width: 150,
                    editorOptions: {
                        displayFormat: "dd.MM.yyyy HH:mm",
                        type: "datetime"
                    }
                }
            ],
            defaultColumns: [
                "ID",
                "Marke",
                "Modell kurz",
                "Score-Stufe",
                "Score-Grenze",
                "Score Aktiv",
                "Kommentar",
                "Letzter Bearbeiter",
                "Letzte Änderung"
            ],
            formStructure: [
                {
                    title: "",
                    items: [
                        {
                            label: "ID",
                            name: "modelScoreId",
                            span: 1,
                            editorOptions: {
                                readOnly: true // ID is not editable
                            }
                        },
                        {
                            label: "Marke",
                            name: "brand",
                            span: 1,
                            required: true,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                dataSource: [
                                    { id: "BMW", name: "BMW" },
                                    { id: "MINI", name: "MINI" }
                                ],
                                valueExpr: "id",
                                displayExpr: "name",
                            }
                        },
                        {
                            label: "Modell kurz",
                            name: "modelShort",
                            span: 1,
                            required: true,
                            editorOptions: {
                                hint:
`Achtung:    
Wähle eine eindeutige Kennung.    
Erstelle einen entsprechenden Eintrag in den Prozeduren.`                                
                            }
                        },
                        {
                            label: "Score-Stufe",
                            name: "modelStep",
                            span: 1,
                            required: true,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                dataSource: this.getDefaultSteps(),
                                valueExpr: "id",
                                displayExpr: "name",
                            }
                        },
                        {
                            label: "Score-Grenze",
                            name: "modelScoreValue",
                            span: 1,
                            editorOptions: {
                                readOnly: true // Automatically calculated
                            }
                        },
                        {
                            label: "Kommentar",
                            name: "comment",
                            span: 2,
                            editorType: "dxTextArea",
                            editorOptions: {
                                autoResizeEnabled: true
                            }
                        },
                        {
                            label: "Score Aktiv",
                            name: "modelScoreActive",
                            span: 1,
                            hideLabel: true,
                            editorType: "dxSwitch",
                            editorOptions: {
                                switchedOffText: "Inaktiv",
                                switchedOnText: "Aktiv",
                                width: 70
                            }
                        }
                    ]
                }
            ]

        }
    },
    async mounted() {
        await this.fetchScoreSteps(),
        await this.fetchModelScores()
    },
    computed: {
        ...mapGetters("modelScores", ["getModelScores"]),
        ...mapGetters("scoreSteps", ["getScoreStepTables"]),
    },
    methods: {
        ...mapActions("scoreSteps", [
            "fetchScoreSteps"
        ]),
        ...mapActions("modelScores", ["fetchModelScores", "saveOrUpdateModelScore"]),
        
        async saveOrUpdate(model) {
            await this.saveOrUpdateModelScore(model); 
            this.$refs["data-grid-component"].gridReload();
        },

        handleEditorPreparing(e) {
            if (e.parentType === "dataRow" || e.parentType === "filterRow") {
                const plainScoreStepTables = JSON.parse(JSON.stringify(this.getScoreStepTables));

                if (e.dataField === "brand") {
                    this.setupBrandEditor(e, plainScoreStepTables);
                } else if (e.dataField === "modelStep") {
                    this.setupModelStepEditor(e, plainScoreStepTables);
                }
            }
        },

        // Sets up the editor for the "brand" column
        setupBrandEditor(e, plainScoreStepTables) {
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;

            e.editorOptions.onValueChanged = (args) => {
                if (defaultValueChangeHandler) {
                    defaultValueChangeHandler(args);
                }

                const rowIndex = e.row?.rowIndex;

                if (rowIndex != null) {
                    const step = e.component.cellValue(rowIndex, "modelStep");
                    const newScoreValue = this.computeModelScoreValue(plainScoreStepTables, args.value, step);

                    e.component.cellValue(rowIndex, "modelScoreValue", newScoreValue);
                }
            };
        },

        // Sets up the editor for the "modelStep" column
        setupModelStepEditor(e, plainScoreStepTables) {
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            const rowIndex = e.row?.rowIndex;

            let selectedSteps = [];
            if (rowIndex != null) {
                const brand = e.component.cellValue(rowIndex, "brand");
                selectedSteps = this.getStepsForBrand(plainScoreStepTables, brand);

                e.editorOptions.onValueChanged = (args) => {
                    if (defaultValueChangeHandler) {
                        defaultValueChangeHandler(args);
                    }

                    const newScoreValue = this.computeModelScoreValue(plainScoreStepTables, brand, args.value);
                    e.component.cellValue(rowIndex, "modelScoreValue", newScoreValue);
                };
            }

            // Assign the computed dataSource
            e.editorOptions.dataSource = selectedSteps || this.getDefaultSteps();
        },

        // Helper method to compute the score value based on brand and step
        computeModelScoreValue(plainScoreStepTables, brand, step) {
            const scoreMappings = plainScoreStepTables.reduce((acc, entry) => {
                acc[entry.title] = entry.scoreSteps.reduce((steps, stepData) => {
                    steps[stepData.step] = stepData.scoreValue;
                    return steps;
                }, {});
                return acc;
            }, {});

            const stepKey = step?.toString() ?? null;
            return scoreMappings[brand]?.[stepKey] ?? null;
        },

        // Helper method to get steps for a specific brand
        getStepsForBrand(plainScoreStepTables, brand) {
            return plainScoreStepTables
                .find(table => table.title === brand)
                ?.scoreSteps.map(step => ({ id: step.step, name: step.step })) || [];
        },

        // Returns default steps for the modelStep editor
        getDefaultSteps() {
            return [
                { id: -3, name: -3 },
                { id: -2, name: -2 },
                { id: -1, name: -1 },
                { id: 0, name: 0 },
                { id: 1, name: 1 },
                { id: 2, name: 2 },
                { id: 3, name: 3 }
            ];
        }
    }
}
</script>